 (function($) {

  var Sage = {
    'common': {
      init: function() {
        $(document).foundation();
      }
    },
    'a_propos': {
      init: function() {
        window.sr = new ScrollReveal();
        sr.reveal('.people');
        sr.reveal('.instagram', { duration: 900, scale: 1 });
        sr.reveal('.people', { duration: 1500, scale: 1 });
      },
      finalize: function() {
      }
    },
    'home': {
      init: function() {
        window.sr = new ScrollReveal();
        sr.reveal('.feed-item', { duration: 1500, scale: 1 });
        sr.reveal('.instagram .columns', { delay: 50, scale:0, duration: 800 }, 50);
      },
      finalize: function() {
      }
    },
    'archive': {
      init: function() {
        window.sr = new ScrollReveal();
        sr.reveal('.feed-item', { duration: 1000, scale: 1 });
      },
      finalize: function() {
      }
    },
    'single': {
      init: function() {
       window.sr = new ScrollReveal();
       sr.reveal('.instagram', { duration: 900, scale: 1 });
       sr.reveal('.instagram .columns', { delay: 50, scale:0, duration: 800 }, 50);

       $('a.twitter-share').click(function(e){
        e.preventDefault();
        var loc = $(this).attr('href');
        var title  = encodeURIComponent($(this).attr('title'));
        window.open('http://twitter.com/share?url=' + loc + '&text=' + title + '&', 'twitterwindow', 'height=450, width=550, top='+($(window).height()/2 - 225) +', left='+$(window).width()/2 +', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
      });

       var progressBar  =  $('progress');
       var $document    =  $( document ),
       $window          =  $( window ),
       wScrollCurrent   =  0,
       featuredImage    =  $('article .entry-content').offset();

       $window.on( 'scroll', function() {
        wScrollCurrent   =    $window.scrollTop();

        if( wScrollCurrent > 450 ) {
          $('.single-top').removeClass('single-top--hidden');
        } else {
          $('.single-top').addClass('single-top--hidden');
        }

      });

       var getMax = function(){
        return $(document).height() - $(window).height();
      };

      var getValue = function(){
        return $(window).scrollTop();
      };

      if ('max' in document.createElement('progress')) {
        progressBar.attr({ max: getMax() });

        $(document).on('scroll', function(){
          progressBar.attr({ value: getValue() });
        });

        $(window).resize(function(){
          progressBar.attr({ max: getMax(), value: getValue() });
        });

      } else {
        var max = getMax(),
        value, width;

        var getWidth = function() {
          value = getValue();
          width = (value/max) * 100;
          width = width + '%';
          return width;
        };

        var setWidth = function(){
          progressBar.css({ width: getWidth() });
        };

        $(document).on('scroll', setWidth);
        $(window).on('resize', function(){
          max = getMax();
          setWidth();
        });
      }
    }
  }
};

var UTIL = {
  fire: function(func, funcname, args) {
    var fire;
    var namespace = Sage;
    funcname = (funcname === undefined) ? 'init' : funcname;
    fire = func !== '';
    fire = fire && namespace[func];
    fire = fire && typeof namespace[func][funcname] === 'function';

    if (fire) {
      namespace[func][funcname](args);
    }
  },
  loadEvents: function() {
    UTIL.fire('common');

    $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
      UTIL.fire(classnm);
      UTIL.fire(classnm, 'finalize');
    });

    UTIL.fire('common', 'finalize');
  }
};

$(document).ready(UTIL.loadEvents);

})(jQuery);
